//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-556:_1304,_144,_6436,_3687,_5004,_9807,_1540,_780;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-556')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-556', "_1304,_144,_6436,_3687,_5004,_9807,_1540,_780");
        }
      }catch (ex) {
        console.error(ex);
      }